<template>
  <div>
    <b-spinner
      v-if="loading"
      variant="primary"
      label="Spinning"
      class="position-absolute"
      style="top:50%; left:48%;"
    />
    <b-card v-else>
      <validation-observer ref="nasUpdate">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group label="Name">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required|max:255"
                >
                  <b-form-input
                    v-model="form.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Name"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="NAS Router IP">
                <validation-provider
                  #default="{ errors }"
                  name="NAS Router IP"
                  vid="ip_address"
                  rules="max:255|required"
                >
                  <b-form-input
                    v-model="form.ip_address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="NAS Router IP"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Endpoint port">
                <validation-provider
                  #default="{ errors }"
                  name="Endpoint port"
                  vid="endpoint_port"
                  rules="max:255|required"
                >
                  <b-form-input
                    v-model="form.endpoint_port"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Endpoint port"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="NAS Router Public IP">
                <validation-provider
                  #default="{ errors }"
                  name="NAS Router Public IP"
                  vid="public_ip_address"
                  rules="max:255"
                >
                  <b-form-input
                    v-model="form.public_ip_address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="NAS Router Public IP"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="NAS Router Public URL">
                <validation-provider
                  #default="{ errors }"
                  name="NAS Router Public URL"
                  vid="ip_address"
                  rules="max:255|url"
                >
                  <b-form-input
                    v-model="form.public_url"
                    :state="errors.length > 0 ? false:null"
                    placeholder="NAS Router Public URL"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="RADIUS Secret">
                <validation-provider
                  #default="{ errors }"
                  name="RADIUS Secret"
                  vid="secret"
                  rules="max:60"
                >
                  <b-form-input
                    v-model="form.secret"
                    :state="errors.length > 0 ? false:null"
                    placeholder="RADIUS Secret"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Vendor model">
                <validation-provider
                  #default="{ errors }"
                  name="Vendor model"
                  vid="vendor_model"
                  rules="max:255"
                >
                  <b-form-input
                    v-model="form.vendor_model"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Vendor model"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Purpose"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Purpose"
                  vid="purpose"
                >
                  <v-select
                    v-model="form.purpose"
                    :options="purposesList"
                    :reduce="purpose => purpose.id"
                    label="name"
                    :searchable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Region"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="region_id"
                  vid="region_id"
                  rules="required"
                >
                  <v-select
                    v-model="form.region_id"
                    :options="regionsList"
                    :reduce="region => region.id"
                    label="name"
                    :searchable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Location"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Location"
                  vid="location"
                  rules="required|max:255"
                >
                  <b-form-input
                    v-model="form.location"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Location"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="currentRole === 'simrush'"
                label="Owner"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Owner"
                  vid="company_id"
                >
                  <v-select
                    v-model="form.company_id"
                    :options="companyList"
                    :reduce="company => company.id"
                    label="name"
                    @search="onSearchCompany($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Pools"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Pools"
                  vid="subnetIds"
                >
                  <v-select
                    v-model="form.subnetIds"
                    :options="poolsList"
                    :reduce="subnet => subnet.id"
                    label="name"
                    :searchable="false"
                    multiple
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BSpinner,
} from 'bootstrap-vue'
import {
  required, max, integer,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import { mapActions, mapState } from 'vuex'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      loading: false,
      currentRole: '',
      form: {
        name: null,
        ip_address: null,
        endpoint_port: null,
        public_ip_address: null,
        public_url: null,
        secret: null,
        vendor_model: null,
        subnetIds: [],
        purpose: null,
        region_id: null,
        location: null,
        company_id: null,
      },
      required,
      max,
      integer,
    }
  },
  computed: {
    ...mapState('nas', {
      purposesList: 'purposesList',
      regionsList: 'regionsList',
      companyList: 'companyList',
      poolsList: 'poolsList',
      item: 'item',
    }),
  },
  async mounted() {
    const { id } = this.$route.params
    this.loading = true
    // eslint-disable-next-line prefer-destructuring
    this.currentRole = JSON.parse(localStorage.getItem('userData')).roles[0]
    if (id) {
      await Promise.all([this.getPurpose(), this.getPools(), this.getRegions()])
      await this.fetchModel(id)
    } else {
      this.$router.push({ name: 'error-404' })
    }
    this.loading = false
  },
  methods: {
    ...mapActions('nas', [
      'getPurpose',
      'getRegions',
      'getCompanies',
      'getPools',
      'getItem',
      'updateItem',
    ]),
    onSearchCompany(query) {
      if (!query) {
        return
      }
      this.getCompanies(query)
    },
    async fetchModel(id) {
      try {
        await this.getItem(id)
        this.form.name = this.item.name
        this.form.id = this.item.id
        this.form.ip_address = this.item.ip_address
        this.form.endpoint_port = this.item.endpoint_port
        this.form.public_ip_address = this.item.public_ip_address
        this.form.public_url = this.item.public_url
        this.form.secret = this.item.secret
        this.form.location = this.item.location
        this.form.vendor_model = this.item.vendor_model
        if (this.item.company) {
          this.getCompanies(this.item.company.name)
          this.form.company_id = this.item.company.id
        }
        if (this.item.purpose) {
          this.form.purpose = this.item.purpose.id
        }
        if (this.item.region) {
          this.form.region_id = this.item.region.id
        }
        if (this.item.subnets) {
          this.item.subnets.forEach(item => {
            this.form.subnetIds.push(item.id)
          })
        }
      } catch (error) {
        const { status } = error.response
        if (status === 404) {
          this.$router.push({ name: 'error-404' })
        }
        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    async validationForm() {
      const valid = await this.$refs.nasUpdate.validate()
      if (valid) {
        try {
          const payload = {
            ...this.form,
            public_url: this.form.public_url.includes('http') ? this.form.public_url : `http://${this.form.public_url}`,
          }
          await this.updateItem(payload)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'nas-list' })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.nasUpdate.setErrors(fields)
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
