var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('b-spinner',{staticClass:"position-absolute",staticStyle:{"top":"50%","left":"48%"},attrs:{"variant":"primary","label":"Spinning"}}):_c('b-card',[_c('validation-observer',{ref:"nasUpdate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Name"}},[_c('validation-provider',{attrs:{"name":"Name","vid":"name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Name","autocomplete":"off"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"NAS Router IP"}},[_c('validation-provider',{attrs:{"name":"NAS Router IP","vid":"ip_address","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"NAS Router IP","autocomplete":"off"},model:{value:(_vm.form.ip_address),callback:function ($$v) {_vm.$set(_vm.form, "ip_address", $$v)},expression:"form.ip_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Endpoint port"}},[_c('validation-provider',{attrs:{"name":"Endpoint port","vid":"endpoint_port","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Endpoint port","autocomplete":"off"},model:{value:(_vm.form.endpoint_port),callback:function ($$v) {_vm.$set(_vm.form, "endpoint_port", $$v)},expression:"form.endpoint_port"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"NAS Router Public IP"}},[_c('validation-provider',{attrs:{"name":"NAS Router Public IP","vid":"public_ip_address","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"NAS Router Public IP","autocomplete":"off"},model:{value:(_vm.form.public_ip_address),callback:function ($$v) {_vm.$set(_vm.form, "public_ip_address", $$v)},expression:"form.public_ip_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"NAS Router Public URL"}},[_c('validation-provider',{attrs:{"name":"NAS Router Public URL","vid":"ip_address","rules":"max:255|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"NAS Router Public URL","autocomplete":"off"},model:{value:(_vm.form.public_url),callback:function ($$v) {_vm.$set(_vm.form, "public_url", $$v)},expression:"form.public_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"RADIUS Secret"}},[_c('validation-provider',{attrs:{"name":"RADIUS Secret","vid":"secret","rules":"max:60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"RADIUS Secret","autocomplete":"off"},model:{value:(_vm.form.secret),callback:function ($$v) {_vm.$set(_vm.form, "secret", $$v)},expression:"form.secret"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Vendor model"}},[_c('validation-provider',{attrs:{"name":"Vendor model","vid":"vendor_model","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Vendor model","autocomplete":"off"},model:{value:(_vm.form.vendor_model),callback:function ($$v) {_vm.$set(_vm.form, "vendor_model", $$v)},expression:"form.vendor_model"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Purpose","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Purpose","vid":"purpose"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.purposesList,"reduce":function (purpose) { return purpose.id; },"label":"name","searchable":false},model:{value:(_vm.form.purpose),callback:function ($$v) {_vm.$set(_vm.form, "purpose", $$v)},expression:"form.purpose"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Region","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"region_id","vid":"region_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.regionsList,"reduce":function (region) { return region.id; },"label":"name","searchable":false},model:{value:(_vm.form.region_id),callback:function ($$v) {_vm.$set(_vm.form, "region_id", $$v)},expression:"form.region_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Location","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Location","vid":"location","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Location","autocomplete":"off"},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.currentRole === 'simrush')?_c('b-form-group',{attrs:{"label":"Owner","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Owner","vid":"company_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.companyList,"reduce":function (company) { return company.id; },"label":"name"},on:{"search":function($event){return _vm.onSearchCompany($event)}},model:{value:(_vm.form.company_id),callback:function ($$v) {_vm.$set(_vm.form, "company_id", $$v)},expression:"form.company_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1103392654)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Pools","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Pools","vid":"subnetIds"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.poolsList,"reduce":function (subnet) { return subnet.id; },"label":"name","searchable":false,"multiple":""},model:{value:(_vm.form.subnetIds),callback:function ($$v) {_vm.$set(_vm.form, "subnetIds", $$v)},expression:"form.subnetIds"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }